<template>
	<div class="lookorder">
		<el-container>
			<MyHeader :userName="userName"></MyHeader>
			<div class="bo"></div>
			<Nav></Nav>
			<div class="ocon" style="height:95%;overflow-y: auto;">
					<el-main>
					<div class="ordertitle">订单详情</div>
					<div class="baseinfocon">
						<div class="baseinfotitle">基础信息</div>
						<el-table style="width: 100%" :data="doctorInfo">
							<el-table-column label="医师姓名" prop="dname">
							</el-table-column>
							<el-table-column label="联系方式" prop="phone">
							</el-table-column>
							<el-table-column label="公司名称" prop="company">
							</el-table-column>
						</el-table>
						<el-table style="width: 100%" :data="patientInfo">
							<el-table-column label="患者编号" prop="patientNum">
							</el-table-column>
							<el-table-column label="患者性别" prop="patientGender">
							</el-table-column>
							<el-table-column label="出生日期" prop="patientNum">
							</el-table-column>
						</el-table>
						<el-table style="width: 100%" :data="addressInfo">
							<el-table-column label="收货地址" prop="cityInfo">
							</el-table-column>
						</el-table>
					</div>
					<!-- 治疗方案-->
					<div class="baseinfocon">
						<div class="baseinfotitle">治疗方案</div>
						<el-table style="width: 100%" :data="jituoInfo">
							<el-table-column label="基托" prop="jt">
							</el-table-column>
						</el-table>
						<el-table style="width: 100%" :data="chanpinInfo">
							<el-table-column label="产品" prop="cp">
							</el-table-column>
						</el-table>
						<!-- <el-table style="width: 100%" :data="addressInfo">
							<el-table-column label="收货地址" prop="cityInfo">
							</el-table-column>
						</el-table> -->
					</div>
					<!-- 治疗方案 -->
					<div class="baseinfocon">
						<div class="baseinfotitle">治疗方案</div>
						<div class="fangancon">
							{{planDoctor}}
						</div>
						<div class="yaimg">
							<div class="baseinfotitle" style="padding-top: 1.25rem;">医师提交牙位图片</div>
							<div style="padding-top: 0.625rem;">地址:{{yurl}}</div>
							<img id="yachiid" width="150" height="150"/>
						</div>
					</div>
					<!-- 文件资源 -->
					<div class="baseinfocon">
						<div class="baseinfotitle">X光片</div>
						<el-table style="width: 100%" :data="xguaninfo">
							<el-table-column label="图片" prop="type">
							</el-table-column>
							<el-table-column label="上传日期" prop="date">
							</el-table-column>
							<el-table-column label="下载地址" prop="href">
							</el-table-column>
						</el-table>
						<div class="baseinfotitle">口扫</div>
						<el-table style="width: 100%" :data="kousaoinfo">
							<el-table-column label="图片" prop="type">
							</el-table-column>
							<el-table-column label="上传日期" prop="date">
							</el-table-column>
							<el-table-column label="下载地址" prop="href">
							</el-table-column>
						</el-table>
						<div class="baseinfotitle">口内</div>
						<el-table style="width: 100%" :data="kouneiinfo">
							<el-table-column label="图片" prop="type">
							</el-table-column>
							<el-table-column label="上传日期" prop="date">
							</el-table-column>
							<el-table-column label="下载地址" prop="href">
							</el-table-column>
						</el-table>
						<div class="baseinfotitle">面向</div>
						<el-table style="width: 100%" :data="mianxianginfo">
							<el-table-column label="图片" prop="type">
							</el-table-column>
							<el-table-column label="上传日期" prop="date">
							</el-table-column>
							<el-table-column label="下载地址" prop="href">
							</el-table-column>
						</el-table>
					</div>
					<!-- 订单未通过审核显示后台管理员给的建议 -->
					<div class="baseinfocon" v-if="status==2">
						<div class="baseinfotitle">治疗方案反馈</div>
						<div>{{planAdmin}}</div><!-- 管理员的反馈信息 -->
						<div>
							<!-- 管理员反馈的视频信息 -->
							<video id="video" width="320" height="240" controls>
								<source :src="planVideo" type="video/mp4">
								<source :src="planVideo" type="video/ogg">
								您的浏览器不支持 video 标签。
							</video>
						</div>
						<button @click="tongyi" type="button" class="btntongji">同意管理员建议</button>
					</div>
					<div class="baseinfocon">
						<div class="baseinfotitle">订单状态</div>
						<div v-if="status==0">
							创建(未完成)
						</div>
						<div v-if="status==1">
							未审核
						</div>
						<div v-if="status==2">
							审核未通过
						</div>
						<div v-if="status==3">
							已确认(待付款)
						</div>
						<div v-if="status==4">
							已付款(制作中)
						</div>
						<div v-if="status==5">
							<span style="padding-right: 1.25rem;">运输中</span>
							<button @click="comgoods" class="receivegoods" type="button">确认收货</button>
						</div>
						<div v-if="status==6">
							已完成
						</div>
					</div>
				</el-main>
			</div>
			<el-footer style="font-size: 0.875rem; color: #1196AB;">
				Copyright © www.techlion,.com.cn All Rights Reserved.<br>
				山东泰克莱恩 8008888888
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	import MyHeader from '../components/MyHeader.vue'
	import Nav from '../components/Nav.vue'
	export default {
		data: function() {
			return {
				ruleForm: {
					oid: "0"
				},
				userName: '',
				doctorInfo: [], //医师信息
				patientInfo: [], //患者信息
				addressInfo: [], //收货地址
				jituoInfo:[],//基托
				chanpinInfo:[],//产品
				planDoctor:'',
				yurl:'',//牙齿图片地址
				xguaninfo:[],//x光片
				kousaoinfo:[],//口扫info
				kouneiinfo:[],//口内
				mianxianginfo:[],//面向
				planAdmin:'',
				planVideo:'',
				status:''
			}
		},
		methods: {
			//根据oid查看订单信息
			getOrderInfoByOid() {
				this.$axios.get("client/order/getOrderShow", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					console.log(res.data);
					let oInfo = res.data.data;
					console.log(oInfo);
					//医师信息
					this.doctorInfo.push({
						dname: oInfo.doctor.dname,
						phone: oInfo.doctor.phone,
						company: oInfo.doctor.company,
					});
					//患者信息
					this.patientInfo.push({
						patientNum: oInfo.patientNum,
						patientGender: oInfo.patientGender,
						patientBirthday: oInfo.patientBirthday
					});
					//收货地址
					if( oInfo.address!=null){
						this.addressInfo.push({
						cityInfo: oInfo.address.cityInfo + oInfo.address.address
					});
					}
					
					//基托
					let jituo= oInfo.jituo;
					if(jituo.length!=0){
						for(let i=0;i<jituo.length;i++){
							this.jituoInfo.push({
								jt:jituo[i]
							});
						}
					}
					//产品
					let chanpin=oInfo.chanpin;
					if(chanpin.length!=0){
						for(let i=0;i<chanpin.length;i++){
							this.chanpinInfo.push({
								cp:chanpin[i]
							});
						}
					}
					//医师方案
					this.planDoctor=oInfo.planDoctor
					//牙齿图片地址
					if(oInfo.planPdf!=null){
						this.yurl=oInfo.planPdf;
						document.getElementById("yachiid").setAttribute("src",oInfo.planPdf)
					}
					//x光
					this.xguaninfo=oInfo.xray;
					//口扫
					this.kousaoinfo=oInfo.kousao;
					//口内
					this.kouneiinfo=oInfo.kounei
					//面向
					this.mianxianginfo=oInfo.mianxiang
					//状态
					this.status=oInfo.status
					//反馈信息
					if(oInfo.status==2){
						this.planAdmin=oInfo.planAdmin;
						if(oInfo.planVideo!=null){
							this.planVideo=oInfo.planVideo;
						}
					}
					
				}).catch(err => {
					console.log(err);
				});
			},
			//确认收货
			comgoods(){
				this.$axios.get("client/order/completeOrder",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					if(res.data.code==200){
						this.$message({
							message:"确认收货成功",
							type:"success",
							duration:900
						});
						this.$router.push("/main");
					}
				}).catch(err=>{
					console.log(err);
					this.$message({
						message:"确认收货失败",
						type:"error",
						duration:900
					});
				});
			},
			//同意管理员建议
			tongyi(){
				this.$axios.get("client/order/agreeOrder",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					if(res.data.code==200){
						this.$message({
							message:"同意成功",
							type:"success",
							duration:900
						});
						this.$router.push("/main");
					}
				}).catch(err=>{
					console.log(err);
					this.$message({
						message:"同意失败",
						type:"error",
						duration:900
					});
				});
			}
		},
		mounted: function() {
			//获取当前登录的用户名
			let dname = JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			this.userName = dname;
			//设置默认地址
			sessionStorage.setItem("location", "/lookorder")
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			if (oid == null) {
				this.$alert("请填写患者信息", "提示");
				this.$router.push("/addorder/orderone");
			} else {
				this.ruleForm.oid = oid;
				this.getOrderInfoByOid();
			}
		},
		components: {
			MyHeader,
			Nav
		},
	}
</script>

<style scoped="scoped">
	.lookorder {
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, white, #9BEDEB, #28D9D5);
		
	}
	.el-container{
		height: 100%;
	}

	.el-foot {
		height: 10%;
		position: absolute;
		bottom: 0rem;
	}

	.ordertitle {
		height: 3.1875rem;
		line-height: 3.1875rem;
		background-color: white;
		box-shadow: 0px 0px 10px lightgray;
		text-align: left;
		padding-left: 1.25rem;
		border-radius: 0.9375rem;
		font-size: 1.4375rem;
	}

	.baseinfocon {
		margin-top: 0.6rem;
		padding-left: 1.5625rem;
		background-color: white;
		border-radius: 0.9375rem;
		text-align: left;
		padding-right: 1.5625rem;
		box-shadow: 0px 0px 10px lightgray;
	}

	.baseinfotitle {
		height: 2.875rem;
		line-height: 2.875rem;
		border-bottom: 0.0625rem solid #EBEEF5;
		font-size: 1.1rem;
		font-weight: bold;
		/* box-shadow: 0px 0px 10px lightgray; */
		/* border-radius: 0.9375rem;
		padding-left: 0.3125rem; */
	}

	.ocon {
		padding: 0px 6.25rem;
	}
	.el-main{
		overflow-y:auto !important;
	}
	.receivegoods{
		width: 20%;
		background-image: linear-gradient(to right, white, #9BEDEB, #28D9D5);
		height: 2.1875rem;
		line-height: 2.1875rem;
		border: none;
		border-radius: 0.625rem;
		margin-bottom: 0.625rem;
		margin-top: 0.625rem;
	}
</style>
