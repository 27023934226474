<template>
	<div class="nav">
		<div class="nav">
			<ul>
				<li @click="goMain">首页</li>
				<li @click="goAllOrder">订单</li>
				<li>留言</li>
				<li>设置</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		methods:{
			/* 去首页 */
			goMain(){
				console.log(111);
				this.$router.push("/main");
			},
			/* 去所有订单 */
			goAllOrder(){
				this.$router.push("/allorder");
			}
		}
	}
</script>

<style scoped="scoped">
	.nav {
		width: 100%;
		height: 2.5rem;
		line-height: 2.5rem;
		background-image: linear-gradient(to bottom, white);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		box-shadow: 0px 3px 3px lightgray;
	}
	
	.nav ul {
		width: 100%;
	}
	
	.nav ul li {
		list-style: none;
		display: inline-block;
		color: #0E8BA4;
		font-weight: bolder;
		width: 23%;
		cursor: pointer;
	}
</style>
